
<!-- Navbar -->
<app-navbar></app-navbar>

<!-- 1 block -->
<div id="home" class="content">
  <div class="left-block">
    <div class="left-block-top">
      Let's make this place better for our kids using technology.
    </div>
    <div class="left-block-center">
      Be part of the change, preventing Mass Shootings with Innovative Technology and Collective Consciousness.
    </div>
    <div class="left-block-bottom">
      <a class="white-button" href="#how-its-works" >Learn More</a>
      
    </div>
  </div>
  <div class="right-block">
    <img src="../../assets/images/image_home.svg" width="70%" title="wearethestrongest">
  </div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,32L60,69.3C120,107,240,181,360,192C480,203,600,149,720,133.3C840,117,960,139,1080,149.3C1200,160,1320,160,1380,160L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>



<!-- 2 block - how it is works -->
<app-how-its-works></app-how-its-works> 

<!-- 3 block -->
<app-banner1></app-banner1>

<!-- 4 block collaborators -->
<app-collaborators></app-collaborators> 

<!-- 5 block -->
<app-contact></app-contact>

<!-- 6 block -->
<app-banner3></app-banner3>

<!-- 7 block -->
<app-footer></app-footer>


<!-- Contact Modal -->
<div *ngIf='false' class="modal">
  <!-- Modal content -->
  <div class="modal-content">
    <div class="exit-section">
      <div class="exit">
        &#x2715;
      </div>
    </div>
    <div class="modal-title">
      <h2>
        Donate
      </h2>
    </div>
    <div class="modal-description">
      <p>
        Help us to help more people agains the bullying!      
      </p>
    </div>
    <div class="modal-form">
      <div class="input-block">
        <div class="input-title">
            <h5>Firstname</h5>
        </div>
        <div class="input-form-section">
            <input class="input-form" type="text">
        </div>
      </div>
      <div class="input-block">
        <div class="input-title">
            <h5>Lastname</h5>
        </div>
        <div class="input-form-section">
            <input class="input-form" type="text">
        </div>
      </div>
      <div class="input-block">
        <div class="input-title">
            <h5>Email</h5>
        </div>
        <div class="input-form-section">
            <input class="input-form" type="text">
        </div>
      </div>
      <div class="input-block">
        <div class="input-title">
            <h5>Message</h5>
        </div>
        <div class="input-form-section">
          <!-- <input class="input-form" type="text"> -->
            <textarea class="multilinea" maxlength="345" rows="5"></textarea>
        </div>
      </div>
    </div>
    <div class="buttons-zone">
      <button class="blue-button">Donate</button>
    </div>
  </div>
</div>


