<div id="banner1" class="content">
    <div class="bg-img"></div>
    <div class="subcontainer">
      <div class="message">
          <h2 class="text-grey">
            Our project is a revolutionary platform that combines artificial intelligence and data analysis to prevent mass shootings.
            <br> <br> 
            Our mission is to safeguard lives, detect threats early, and promote safety in communities through technology and effective collaboration.
          </h2>


      </div>
    </div>
  </div>
