import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
  public menuVisible: boolean;

  constructor(
  ){
    this.menuVisible = false;
  }

  ngOnInit(): void {
  }

  toggleMenu(){
    this.menuVisible = !this.menuVisible;
  }

  hideMenu(toTag){
    document.getElementById(toTag).scrollIntoView({ behavior: 'smooth' });
    this.menuVisible = false;
  }

  //AL PRESIONAR EL BOTON DE CAMBIA DE IDIOMA
  // changeLanguage(){
  //   this._languagesService.changeLanguage();
  // }

}
