<div id="how-its-works" class="content">


  <div class="section">
    <div class="subtitle">
      What is SafetyPlace?
    </div>
    <div class="block">
      <div class="block-left paragraph">
        <p>
          The project focuses on developing an artificial intelligence platform 
          for the prevention of mass shootings. Its main objective is to utilize 
          machine learning algorithms to analyze behavioral patterns and related 
          data in order to identify potential threats and prevent violent events 
          in the United States.
        <br><br>
          This platform will collect and process data from various sources, such as 
          social media, security agency reports, and government databases.

        </p>
      </div>
      <div class="block-right">
        <img id="kid-image" src="../../assets/images/parent.svg" width="40%" title="student">
      </div>
    </div>
  </div>

  <div class="section">
    <div class="block">
      <div class="block-left">
        <img id="kid-image" src="../../assets/images/kid.svg" width="40%" title="student">
      </div>
      <div class="block-right paragraph">
        <p>

          Through the analysis of this data, early signs of problematic or risky behaviors that may be associated with the planning of a mass shooting will be sought.
          <br><br>
          The system will employ advanced artificial intelligence algorithms to identify patterns and correlations in the collected data. This will enable the detection of risk factors and generate alerts for the relevant authorities, with the aim of intervening preventively and avoiding the occurrence of tragedies.
          <br><br>
          In addition to early detection, the platform will also provide data analysis and visualization tools to assist security agencies and law enforcement professionals in making informed decisions. 
          <br><br>
          These tools will allow for a better understanding of behavioral patterns and a more accurate assessment of potential threats.
          <br><br>
          The platform will be developed using modern technologies and artificial intelligence frameworks. Machine learning models and natural language processing algorithms will be implemented to efficiently understand and analyze large volumes of information.
          <br><br>
          In summary, this project aims to harness the capabilities of artificial intelligence and data analysis to prevent mass shootings in the United States. By detecting and analyzing suspicious behavioral patterns, it seeks to provide an effective tool for authorities and security professionals, thus contributing to the protection of life and the safety of society as a whole.

        </p>
      </div>

    </div>
  </div>

  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#0099ff" fill-opacity="1" d="M0,256L48,234.7C96,213,192,171,288,149.3C384,128,480,128,576,154.7C672,181,768,235,864,256C960,277,1056,267,1152,261.3C1248,256,1344,256,1392,256L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
  </svg> -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#0099ff" fill-opacity="1" d="M0,288L48,282.7C96,277,192,267,288,256C384,245,480,235,576,192C672,149,768,75,864,53.3C960,32,1056,64,1152,101.3C1248,139,1344,181,1392,202.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#0099ff" fill-opacity="1" d="M0,64L48,53.3C96,43,192,21,288,26.7C384,32,480,64,576,106.7C672,149,768,203,864,213.3C960,224,1056,192,1152,181.3C1248,171,1344,181,1392,186.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
  </svg>

  <div id="about" class="section">
    <div class="subtitle">
      <h3>A Little About Me</h3>
    </div>
    <div class="block">
      <div class="block-left paragraph">
        <p>
          I am a father of two young children deeply concerned about their safety and the safety of all the children attending their school. With over a decade of experience in technology, I am leveraging the feasibility and accessibility of technology and artificial intelligence to launch an open-source project. My goal is to create a collaborative community where we can contribute our expertise and make this a safer place for our children.
        </p>
      </div>
      <div class="block-right">
        <img id="students-image" src="../../assets/images/student.svg" width="40%" title="student">
      </div>
    </div>
  </div>
  
  <div class="section">
    <div class="block">
      <div class="block-left">
        <img  id="myself-image" src="../../assets/images/myself.png" width="60%" title="wearethestrongest">
      </div>
      <div class="block-right paragraph">
        <p>
          As a concerned parent, I understand the importance of providing a secure environment for our kids. That’s why I am thrilled to introduce this initiative on a formal platform, dedicated to addressing the safety concerns in our schools. By harnessing the power of technology and the collective knowledge of like-minded individuals, we can proactively enhance the safety measures for our children.
        </p>
      </div>
    </div>
  </div>
  
  <div class="section">
    <div class="block">
      <div class="block-left paragraph">
        <p>
          I invite all individuals who share our passion and are interested in making a difference to join our esteemed community. Whether you possess technical skills, security expertise, educational insights, or simply a strong desire to protect our children, your involvement is invaluable. Together, we can foster a sense of security and ensure our children thrive in a protected educational environment.

          I eagerly look forward to collaborating with each of you on this exciting endeavor. Let us join forces to create a lasting positive impact on the safety of our loved ones.
        </p>
      </div>
      <div class="block-right">
        <img id="principal-image" src="../../assets/images/principal.svg" width="40%" title="wearethestrongest">
      </div>
    </div>
  </div>

  <!-- <div class="section">
      <button class="blue-button">Read More</button>
  </div> -->

</div>