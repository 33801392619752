//Importar modulos del router de angular
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Components
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';


//Arrays de rutas:
const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  // { path: 'signup', component: SignupComponent },
  // { path: 'login', component: LoginComponent },
  // { path: 'faq', component: FaqsComponent },
  { path: '**', component: ErrorComponent }
];


// Evita error angular-error-generic-type-modulewithproviderst-requires-1-type-arguments
declare module "@angular/core" {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}

export const appRoutingProvider: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);