
        <div class="navbar">
            <div class="left-block">
                <img data-aos="fade-right" data-aos-duration="1000" src="../../assets/images/logo.svg" width="50%" title="wearethestrongest">
            </div>

            <div class="center-block">
                <!-- <a class="navbar-options" data-aos="fade-down" data-aos-duration="0" href="#how-its-works">How it's works</a>
                <a class="navbar-options" data-aos="fade-down" data-aos-duration="200" href="#datasources">DataSources</a>
                <a class="navbar-options" data-aos="fade-down" data-aos-duration="300" href="#about">About</a>
                <a class="navbar-options" data-aos="fade-down" data-aos-duration="100" href="#contact">Contact Us</a> -->
            </div>

            <div class="right-block">
                <!-- <button class="white-button">Login In</button> -->
                <!-- <button class="white-button">Sign Up</button> -->
                <a class="navbar-options" data-aos="fade-down" data-aos-duration="0" href="#how-its-works">How It Works</a>
                <a class="navbar-options" data-aos="fade-down" data-aos-duration="200" href="#datasources">Data Sources</a>
                <a class="navbar-options" data-aos="fade-down" data-aos-duration="300" href="#about">About Us</a>
                <a class="navbar-options" data-aos="fade-down" data-aos-duration="100" href="#contact">Contact Us</a>
            </div>


            <div id="menu-mobile">
                
                <img data-aos="fade-left" data-aos-duration="1000" src="../../assets/images/logo.svg" width="50%" title="safetyplace">
                <button id="button-menu" (click)="toggleMenu()">
                    <!-- <input type="checkbox" id="nav-menu1"> -->
                    <label id="nav-icon1">
                        <span></span>
                        <span></span>
                        <span></span>
                      </label>
                </button>

            </div>


            <div *ngIf='menuVisible' id="sidebar">
                <a (click)="hideMenu('how-its-works')" class="navbar-options" data-aos="fade-down" data-aos-duration="0">How it's works</a>
                <a (click)="hideMenu('datasources')" class="navbar-options" data-aos="fade-down" data-aos-duration="100">DataSources</a>
                <a (click)="hideMenu('about')" class="navbar-options" data-aos="fade-down" data-aos-duration="200">About Us</a>
                <a (click)="hideMenu('contact')" class="navbar-options" data-aos="fade-down" data-aos-duration="300">Contact Us</a>
            </div>



        </div>


