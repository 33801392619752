
export class Contact{

constructor(
  public firstName: string,
  public lastName: string,
  public email: string,
  public message: string
){}

}
