<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#0099ff" fill-opacity="1" d="M0,256L48,266.7C96,277,192,299,288,288C384,277,480,235,576,197.3C672,160,768,128,864,117.3C960,107,1056,117,1152,133.3C1248,149,1344,171,1392,181.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
</svg>

<div id="datasources" class="content" >

  <div class="section" id="title-section">
    <div class="subtitle">
      <h3>DataSources</h3>
    </div>

  </div>

  <div class="section">
    <div class="description-section">
      <p class="description">
        These are some of the data sources that gather detailed information 
        on various relevant aspects, which this project aims to support, 
        relying on open-source projects, data from U.S. federal government 
        agencies, databases with statistics on violence events related 
        to mass shootings, and research focused on violence prevention.
      </p>
    </div>

  </div>

  <div class="section" id="cards-section">

    <div class="card">
      <div class="card-img">
        <!-- IMG -->
        <img width="70%" src="https://code.gov/assets/img/logos/code-gov-dark.svg" alt="code.gov">
      </div>
      <div class="card-name">
        Code.gov
      </div>
      <div class="card-info">
        Government initiative promoting transparency and collaboration in software development.
      </div>
      <div class="card-linkedin">
        <a href="https://code.gov/" target="_blank">
          code.gov
        </a>
      </div>
    </div>

    <div class="card">
      <div class="card-img">
        <img width="50%" src="https://www.gunviolencearchive.org/sites/all/themes/gva_theme/images/new-logo.jpg" alt="gunviolencearchive.org">
      </div>
      <div class="card-name">
        GunViolenceArchive.org
      </div>
      <div class="card-info">
        Database tracking gun violence incidents in the United States.
      </div>
      <div class="card-linkedin">
        <a href="        https://www.gunviolencearchive.org/" target="_blank">
          gunviolencearchive.org
        </a>
      </div>
    </div>

    <div class="card">
      <div class="card-img">
        <img width="70%" src="https://health.ucdavis.edu/global/images/wordmarks/UCDavisHealth.svg" alt="health.ucdavis.edu">
      </div>
      <div class="card-name">
        Health.Ucdavis.Edu
      </div>
      <div class="card-info">
        Research program studying violence prevention and public health.
      </div>
      <div class="card-linkedin">
        <a href="https://health.ucdavis.edu/vprp/" target="_blank">
          health.ucdavis.edu/vprp
        </a>
      </div>
    </div>

    <div class="card">
      <div class="card-img">
        <img width="70%" src="https://www.theviolenceproject.org/wp-content/uploads/2018/07/logo-header1.jpg" alt="theviolenceproject.org">
      </div>
      <div class="card-name">
        TheViolenceProject.org
      </div>
      <div class="card-info">
        Research organization focused on studying and understanding violence prevention.
      </div>
      <div class="card-linkedin">
        <a href="https://www.theviolenceproject.org/" target="_blank">
          theviolenceproject.org
        </a>
      </div>
    </div>

  </div>

  


</div>