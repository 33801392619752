<!-- top curve -->
<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,160L80,144C160,128,320,96,480,112C640,128,800,192,960,208C1120,224,1280,192,1360,176L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg> -->

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#0099ff" fill-opacity="1" d="M0,96L120,112C240,128,480,160,720,165.3C960,171,1200,149,1320,138.7L1440,128L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
</svg>

<!-- 1 block -->
<div id="contact" class="content">
  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,32L60,69.3C120,107,240,181,360,192C480,203,600,149,720,133.3C840,117,960,139,1080,149.3C1200,160,1320,160,1380,160L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg> -->
  <div class="left-block">
    <div class="left-block-top">
      Contact
    </div>
    <div class="left-block-center">
      Join us and let's make this place the safest place for our kids
    </div>

    <div *ngIf='sentEmailMessageBanner'>
      <h3 class="messageSent">We will be back to you soon!</h3>
    </div>

    <div class="left-block-bottom">
      <button class="white-button" (click)="formContactToggle()">Touch</button>
    </div>
  </div>
  <div class="right-block">
    <img data-aos="fade-right" data-aos-duration="1000" src="../../assets/images/contact.svg" width="70%" title="wearethestrongest">
  </div>
</div>

<!-- bottom curve -->
<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,32L60,69.3C120,107,240,181,360,192C480,203,600,149,720,133.3C840,117,960,139,1080,149.3C1200,160,1320,160,1380,160L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg> -->
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#0099ff" fill-opacity="1" d="M0,32L80,32C160,32,320,32,480,64C640,96,800,160,960,186.7C1120,213,1280,203,1360,197.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
</svg>


<!-- Contact Modal -->
<div *ngIf='showForm && !sentEmail' class="modal">
  <!-- Modal content -->
  <div class="modal-content">
    <div class="exit-section">
      <div class="exit" (click)="formContactToggle()">
        &#x2715;
      </div>
    </div>
    <div class="modal-title">
      <h2>
        Let's keep in touch
      </h2>
    </div>
    <div class="modal-description">
      <p>
        Please, fill out the form and let us to know what do you thing. We will reply you as soon as possible.
      </p>
    </div>
    <div class="modal-form">
      <div class="input-block">
        <div class="input-title">
            <h5>Firstname</h5>
        </div>
        <div class="input-form-section">
            <input maxlength="25" type="text" name="firstname" #firstname="ngModel" [(ngModel)]="contact.firstName" class="input-form">
        </div>
      </div>
      <div class="input-block">
        <div class="input-title">
            <h5>Lastname</h5>
        </div>
        <div class="input-form-section">
            <input maxlength="25"  type="text" name="lastname" #lastname="ngModel" [(ngModel)]="contact.lastName" class="input-form">
        </div>
      </div>
      <div class="input-block">
        <div class="input-title">
            <h5>Email</h5>
        </div>
        <div class="input-form-section">
            <input maxlength="40"  type="email" name="email" #email="ngModel" [(ngModel)]="contact.email" class="input-form">
        </div>
      </div>
      <div class="input-block">
        <div class="input-title">
            <h5>Message</h5>
        </div>
        <div class="input-form-section">
          <!-- <input class="input-form" type="text"> -->
            <textarea class="multiline" maxlength="345" rows="5" type="text" name="message" #message="ngModel" [(ngModel)]="contact.message"></textarea>
        </div>
      </div>
    </div>

    <div class="distancer"></div>

    <div align='center' class="alert alert-success" *ngIf="status == 'success'">
      Thanks, we'll be back to you soon!
    </div>
    <div align='center' class="alert alert-danger" *ngIf="status == 'error'">
      {{ error }}
    </div>

    <div class="buttons-zone">
      <button [disabled]="disableButton" (click)="sendMessage()" type="button" class="blue-button">Send</button>
    </div>
  </div>
</div>
