<!DOCTYPE html>
<html lang="es" dir="ltr">
  <title>SafetyPlace.us</title>
  <meta charset="UTF-8">
  <!-- <meta name="favicon" content="doish property"> -->
  <meta name="viewport" content="width=device-width, initial-scale=1">

  <head>
    <meta charset="utf-8">
    <title>SafetyPlace.us</title>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"></script>
  </head>

  <body>
      <div id="mainpage">
        <router-outlet></router-outlet>
      </div>
  </body>

</html>
