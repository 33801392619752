<!-- top curve -->
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#ebebeb" fill-opacity="1" d="M0,256L120,234.7C240,213,480,171,720,181.3C960,192,1200,256,1320,288L1440,320L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
</svg>

<!-- IMAGE -->
<div id="footer" class="content">
  <img id="footer-image" src="../../assets/images/logo.svg" title="safetyplace">
</div>




