import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Contact } from '../models/contact.model';

@Injectable()
export class ContactService implements OnInit{

constructor(public _http: HttpClient){
}

ngOnInit(): void {}

//SEND MESSAGE
sendMessage(contact: Contact): Observable<any>{
    // console.log(contact)
    
    const params = JSON.stringify(contact);
    return this._http.post('https://formspree.io/f/xnqkakow', params );
}


}

