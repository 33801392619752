<div id="error" class="content">
  <div class="flex-container">
    <div class="flex-item">
      <img src="../../assets/images/broken.png" width="20%" title="">
    </div>
    <div class="flex-item">
      <h2>ERROR 404</h2>
    </div>

    <div class="flex-item">
      <h3>PAGE NOT FOUND</h3>
    </div>

    <div class="flex-item">
      <a  href="#" >
        <button class="btn btn-success">Return</button>
      </a>
    </div>

  </div>
</div>
  