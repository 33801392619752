import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

//Routing
import { AppRoutingModule } from './app-routing.module';
import { routing, appRoutingProvider } from './app.routing';

//SCROLL SMOOTH

//SERVICES
import { ContactService } from './services/contact.service';

//COMPONENTS
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';

import { ContactComponent } from './components/contact/contact.component';
import { HowItsWorksComponent } from './components/how-its-works/how-its-works.component';
import { CollaboratorsComponent } from './components/collaborators/collaborators.component';
import { FooterComponent } from './components/footer/footer.component';
import { ErrorComponent } from './components/error/error.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { Banner1Component } from './components/banner1/banner1.component';
import { Banner3Component } from './components/banner3/banner3.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CollaboratorsComponent,

    ContactComponent,
    HowItsWorksComponent,
    FooterComponent,
    ErrorComponent,
    NavbarComponent,

    Banner1Component,
    Banner3Component
  ],
  imports: [

    AppRoutingModule,
    routing,
    BrowserModule,
		FormsModule,
		HttpClientModule,
  ],

  providers: [
    appRoutingProvider,
    ContactService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
