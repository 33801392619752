import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Contact } from '../../models/contact.model'
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  public contact: Contact;
  public buttonSubmit: boolean;
  public showForm: boolean;
  public status: string;
  public error: string;
  public sentEmail: boolean;
  public sentEmailMessageBanner: boolean;
  public disableButton: boolean;

  

  constructor(
    public _contactService: ContactService
  ){
    this.contact = new Contact("", "" ,"" ,"");
    this.error = null;
    this.status = null;
    this.buttonSubmit = false;
    this.showForm = false;
    this.sentEmail = false;
    this.sentEmailMessageBanner = false;
    this.disableButton = false;
  }

  ngOnInit(): void {
  }

  sendMessage(){
    
    if( 
      this.contact.firstName != "" &&
      this.contact.lastName != "" &&
      this.contact.email != "" &&
      this.contact.message != ""
      ){
        this.disableButton = true;
        this.buttonSubmit = true; 
        this.error = null;
        this.status = null;

        
        this._contactService.sendMessage(this.contact).subscribe( 
          response => {
                // contactForm.reset();
                this.status = "success";
                this.error = null;
                

                setTimeout(() => {
                  this.status = null;
                  this.error = null;
                  this.sentEmail = true;

                  // this.buttonSubmit = false;
                  this.contact.firstName = "";
                  this.contact.lastName = "";
                  this.contact.email = "";
                  this.contact.message = "";
                }, 3000);
          },
          error => {
            // this.buttonSubmit = true;
            this.status = "error";
            this.error = "Message was not sent, please, check the information and try later.";

            setTimeout(() => {
              this.disableButton = false;
              this.status = null;
              this.error = null;
              this.buttonSubmit = false;
            }, 5000);
            
          })

    }else{
          // this.buttonSubmit = true;
          this.status = "error";
          this.error = "You must fill out the form";

          setTimeout(() => {
            this.status = null;
            this.error = null;
            this.buttonSubmit = false;
          }, 5000);
      }
    }

  formContactToggle(){
    if (this.sentEmail){
      this.sentEmailMessageBanner = true;
      setTimeout(() => { this.sentEmailMessageBanner = false; }, 5000);
    }

    this.showForm = !this.showForm;
  }
  
}