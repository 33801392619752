import { Component, OnInit  } from '@angular/core';
import * as $ from 'jquery';
import * as AOS from 'aos';

import {enableProdMode} from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';  // Google Analytics
import { filter } from 'rxjs/operators';                  // Google Analytics
declare var gtag;     

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  public title: string;
  // public language: string;

  constructor(
    public router: Router // Google Analytics
  ){
    // production mode
    enableProdMode();

     // Google Analytics
     const navEndEvents = router.events.pipe(
      filter( event => event instanceof NavigationEnd )
    );
    navEndEvents.subscribe( ( event: NavigationEnd ) => {
      gtag('config', 'G-KG9TD772YB', {
        'page_path' : event.urlAfterRedirects
      });
    })

    // Smooth scroll
    $(document).ready(function(){
      // Add smooth scrolling to all links
      $("a").on('click', function(event) {
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
          // Prevent default anchor click behavior
          event.preventDefault();
          // Store hash
          const hash = this.hash;
          const offset = 200;
          // Using jQuery's animate() method to add smooth page scroll
          // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
          $('html, body').animate({
            scrollTop: $(hash).offset().top - offset
          }, 1800, function(){
            // Add hash (#) to URL when done scrolling (default click behavior)
            // window.location.hash = hash;
          });
        }
      });
    });


   }

  ngOnInit(){
    AOS.init();
  }





}
